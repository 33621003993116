import React, { useEffect } from "react";
import { AppUseContext } from "./AppContext";
import { IoMdClose } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";

const VideoPopup = () => {
  const { isPopupActive, setIsPopupActive, popupVideo } = AppUseContext();

  useEffect(() => {
    if (!isPopupActive) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [isPopupActive]);

  const popupVariants = {
    view: { opacity: 1, y: 0 },
    hide: { opacity: 0, y: 100 },
  }

  return (
    <>
      <AnimatePresence>
        {isPopupActive && (
          <motion.div className="popup-component-parent" variants={popupVariants} initial="hide" animate="view" exit="hide" >
            <div
              className="click-outside"
              onClick={() => setIsPopupActive(false)}
            ></div>
            <div className="overlay">
              <iframe
                width="900"
                height="500"
                src={popupVideo}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
            <div
              className="close-btn"
              onClick={() => {
                setIsPopupActive(false);
              }}
            >
              <IoMdClose />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default VideoPopup;
