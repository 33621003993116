import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { AppUseContext } from "../AppContext";
import LogoImg from "../../assets-new/images/logo (1).svg";
// import OvInfo from "./OvInfo";
import LpFooter from "./LpFooter";
import MetaData from "../MetaData";

const LpThankYouPage = () => {
  const { setPageErr, setIsEcommerceLandingPage } = AppUseContext();
  const pathname = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    setPageErr(true);
    if (pathname.pathname === "/ecommerce-mumbai/thank-you" || pathname.pathname === "/ecommerce-bangalore/thank-you") {
      setIsEcommerceLandingPage(true)
    }
    // eslint-disable-next-line
  }, [pathname]);
  return (
    <>
      <MetaData elseContent={true} />
      <div className="commonSection landingPage-thank-you">
        <div className="text-center thank-you-container">
          <div className="logo-content">
            <img
              src={LogoImg}
              alt=""
              style={{
                marginBottom: 20,
              }}
            />
          </div>
          <div className="thank-you-content">
            <h2 className="sec_title">Thank you</h2>
            <p>YOUR SUBMISSION IS RECEIVED AND WE WILL CONTACT YOU SOON.</p>
            <span
              onClick={() => {
                navigate(-1);
              }}
              style={{
                color: "#000",
                cursor: "pointer",
              }}
            >
              <FaArrowLeft /> Back to Home
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#fafbfc",
        }}
      >
        <LpFooter />
      </div>
    </>
  );
};

export default LpThankYouPage;
