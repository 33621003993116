import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/insights.css";
import Review2020 from "../../assets-new/images/blog/review-2020.jpg";
import MetaData from "../MetaData";
import whiteboardebookPdf from "../../pdf/whiteboardebook.pdf";
import ovVideosProcess from "../../pdf/orange-videos-process.pdf";
import sevenMostEffectiveVideoStyle from "../../pdf/7-most-effective-video-styles.pdf";
import yearReview2020 from "../../pdf/year-in-review-2020.pdf";
import sevenElements from "../../pdf/7elements.pdf";
import ExplainerVideoScripts from "../../pdf/explainer-video-scripts.pdf";
import REIMAGININGVIDEOS from "../../assets-new/images/blog/reimagining-videos-thumbnail.png";
import BRIDGINGTHEKNOWLEDGEGAP from "../../assets-new/images/blog/how-to-convert-thumbail.png";
import PowerOfCustomerTestimonialVideosThumbnail from "../../assets-new/images/blog/PowerOfCustomerTestimonialVideosThumbnail.webp";
import SmartphoneFilmmakingHacks from "../../assets-new/images/blog/SmartphoneFilmmakingHacks.png";
import HeartwarmingFathersDayBrandCampaigns from "../../assets-new/images/blog/5-heartwarming-fathers-day-brand-campaigns.webp";
import recceImg from "../../assets-new/images/blog/recce-thumbnail.jpg";
import voImg from "../../assets-new/images/blog/vo-thumbnail.jpg";
import prodImg from "../../assets-new/images/blog/prod-design-thumbnail.png";
import shotsThumbImg from "../../assets-new/images/blog/different-types-of-shots-thumb.jpg";
import avBreakdownImg from "../../assets-new/images/blog/av-breakdown-blog-thumbnail.jpg";
import SymboImg from "../../assets-new/images/blog/Symbo Blog tumbnail.jpg";
import ProductionWorkflowImg from "../../assets-new/images/blog/Our Corporate Video Production Workflow blog thumbnail.jpg";
import corporateVideoImg from "../../assets-new/images/blog/corporate-video-thumbnail.jpg";
import FourDsVideoProImg from "../../assets-new/images/blog/4ds-of-video-production-thumbnail.jpg";
import FiveFactorsAffectPriceImg from "../../assets-new/images/blog/5-factors-that-affect-the-price-of-your-video-thumbnail.jpg";
import socialMediaImg from "../../assets-new/images/blog/social-media-thumbnail.jpg";
import prosAndConsImg from "../../assets-new/images/blog/pros-and-cons-banner-thumbnail.jpg";
import stockFootageInVideoImg from "../../assets-new/images/blog/benefits-of-using-stock-footage-in-videos-thmbnail-min.jpg";
import impOfStoryBoardingImg from "../../assets-new/images/blog/importance-of-storyboarding-blog-thumbnail-min.jpg";
import howWeMakeOurVideos from "../../assets-new/images/blog/How-we-make-our-videos-thumbnail.jpg";
import avinabImg from "../../assets-new/images/blog/avinab-thumb.jpg";
import sevenBlog from "../../assets-new/images/blog/7-effective-blog thumbnail.jpg";
import dates20201030 from "../../assets-new/images/blog/2020-10-30.jpg";
import sevenElementsImg from "../../assets-new/images/blog/7-elements.jpg";
import explainerVideoImg from "../../assets-new/images/blog/explainer-video-old.jpg";
import latestWhiteBoardImg from "../../assets-new/images/blog/latest-whiteboards.jpg";

const blogItems = [
  {
    imageSrc: PowerOfCustomerTestimonialVideosThumbnail,
    link: "/power-of-customer-testimonial-videos",
    title:
      "The Power of Customer Testimonial Videos: Why They Matter for Your Business",
  },
  {
    imageSrc: SmartphoneFilmmakingHacks,
    link: "/smartphone-filmmaking-hacks-shoot-pro-quality-videos-with-just-your-phone",
    title:
      "Smartphone Filmmaking Hacks: Shoot Pro Quality Videos With Just Your Phone",
  },
  {
    imageSrc: HeartwarmingFathersDayBrandCampaigns,
    link: "/5-heartwarming-fathers-day-brand-campaigns",
    title: "5 Heartwarming Father’s Day Brand Campaigns",
  },
  {
    imageSrc: REIMAGININGVIDEOS,
    link: "/curating-content-that-meets-every-audiences-need",
    title:
      "REIMAGINING VIDEOS: CURATING CONTENT THAT MEETS EVERY AUDIENCE’S NEED",
  },
  {
    imageSrc: BRIDGINGTHEKNOWLEDGEGAP,
    link: "/how-to-convert-client-briefs-into-compelling-video-scripts",
    title:
      "BRIDGING THE KNOWLEDGE GAP: HOW TO CONVERT CLIENT BRIEFS INTO COMPELLING VIDEO SCRIPTS?",
  },
  {
    imageSrc: recceImg,
    link: "/importance-of-recce",
    title: "Importance of Recce",
  },
  {
    imageSrc: voImg,
    link: "/voice-over-and-its-role-in-videos",
    title: "Voice over and its role in videos",
  },
  {
    imageSrc: prodImg,
    link: "/importance-of-production-design",
    title: "Importance of Production Design",
  },
  {
    imageSrc: shotsThumbImg,
    link: "/types-of-shots-in-live-shoot-videos",
    title: "Types of Shots in Live Shoot Videos",
  },
  {
    imageSrc: avBreakdownImg,
    link: "/av-breakdown-a-process-never-to-skip",
    title: "AV Breakdown - A process never to skip",
  },
  {
    imageSrc: SymboImg,
    link: "/symbo-a-digital-ad-film",
    title: "Symbo - A Digital Ad Film",
  },
  {
    imageSrc: ProductionWorkflowImg,
    link: "/Our-Corporate-Video-Production-Workflow",
    title: "Our Corporate Video Production Workflow",
  },
  {
    imageSrc: corporateVideoImg,
    link: "/How-Much-Does-A-Corporate-Video-Cost",
    title: "How Much Does A Corporate Video Cost?",
  },
  {
    imageSrc: FourDsVideoProImg,
    link: "/4ds-of-video-production",
    title: "4Ds of Video Production",
  },
  {
    imageSrc: FiveFactorsAffectPriceImg,
    link: "/5-factors-that-affect-the-price-of-your-video",
    title: "5 Factors that Affect the Price of your Video",
  },
  {
    imageSrc: socialMediaImg,
    link: "/the-importance-of-video-content-on-social-media",
    title: "The Importance of Video Content on Social Media",
  },
  {
    imageSrc: prosAndConsImg,
    link: "/pros-and-cons-of-2d-animations",
    title: "Pros and Cons of 2D Animations",
  },
  {
    imageSrc: stockFootageInVideoImg,
    link: "/benefits-of-using-stock-footage-in-videos",
    title: "Benefits of using stock footage in videos",
  },
  {
    imageSrc: impOfStoryBoardingImg,
    link: "/the-importance-of-storyboarding",
    title: "The importance of storyboarding",
  },
  {
    imageSrc: howWeMakeOurVideos,
    link: ovVideosProcess,
    isPdf: true, // mention isPdf object key value to true only if it's pdf file
    title: "Orange Videos - Process",
  },
  {
    imageSrc: avinabImg,
    link: "/lights-camera-action",
    title: "Lights, Camera, Action!",
  },
  {
    imageSrc: sevenBlog,
    link: sevenMostEffectiveVideoStyle,
    isPdf: true,
    title: "7 Most Effective Video Styles",
  },
  {
    imageSrc: Review2020,
    link: yearReview2020,
    isPdf: true,
    title: "Year in Review - 2020",
  },
  {
    imageSrc: dates20201030,
    link: "/new-office",
    title: "Starting Afresh with a New Office",
  },
  {
    imageSrc: sevenElementsImg,
    link: sevenElements,
    isPdf: true,
    title: "7 elements every video needs",
  },
  {
    imageSrc: explainerVideoImg,
    link: ExplainerVideoScripts,
    title: "Explainer video scripts",
    isPdf: true,
  },
  {
    imageSrc: latestWhiteBoardImg,
    link: whiteboardebookPdf,
    isPdf: true,
    title: "Everything about whiteboard videos",
  },
];

const InsightsPage = () => {
  const pathname = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <MetaData
        pageTitle="Insights | Explainer Video Production Services Mumbai - Orange Videos"
        metaDdescription="The importance of video in the digital marketing world is hard to overestimate. But, how much do you really know about video marketing? In this section, we share our knowledge on how video can help you achieve your marketing objectives and more."
      />
      <section className="pageBanner insights-banner InsightsPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content text-center ">
                <h4>
                  <Link to="/">home</Link> - Insights
                </h4>
                <h1>Insights</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <section className="commonSection blogPage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4 className="sub_title red_color">our resources</h4>
              <h2 className="sec_title">latest insights</h2>
              <p className="psub_heading">
                The importance of video in the digital marketing world is hard
                to overestimate. But, how much do you really know about video
                marketing? In this section, we share our knowledge on how video
                can help you achieve your marketing objectives and more. All for
                FREE.
              </p>
            </div>
          </div>

          {/* <!--<div className="row">--> */}

          <div
            className="row d-flex"
            style={{
              flexWrap: "wrap",
            }}
          >
            {blogItems.map((item, index) => (
              <div
                key={index}
                className="col-lg-4 col-sm-6 col-md-4"
              // style={{ height: "450px" }}
              >
                <div className="latestBlogItem">
                  <div className="lbi_thumb">
                    <img
                      src={item.imageSrc}
                      alt={item.title}
                      style={{ height: "300px" }}
                    />
                  </div>
                  <div className="lbi_details">
                    <h2>
                      {/* {item.link.startsWith("http") ? (
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      ) : (
                        <Link to={item.link}></Link>
                        )} */}
                      {item.isPdf ? (
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      ) : (
                        <Link to={item.link}>{item.title}</Link>
                      )}
                    </h2>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default InsightsPage;
