import React from "react";
// import { FaPlay } from "react-icons/fa6";
// import CoinDCX from "../../assets-new/videos/bts/coinDCX.mp4";
// import hapagLloyd from "../../assets-new/videos/bts/hapag-lloyd-optimized.mp4";
// import bnk from "../../assets-new/videos/bts/bnk.mp4";
// import bnk2 from "../../assets-new/videos/bts/bnk-v1.mp4";
// import mentco from "../../assets-new/videos/bts/mentco.mp4";
// import zeroB from "../../assets-new/videos/bts/zeroB.mp4";
// import greenScreen from "../../assets-new/videos/bts/greenScreen.mp4";
// import industrialShootKelvion from "../../assets-new/videos/bts/industrial-shoot-Kelvion.mp4";
// import { FaPause } from "react-icons/fa";
// import { useInView } from "framer-motion";
// import BtsVideoContainer from "./BtsVideoContainer";
import coindcx from "../../assets-new/videos/bts/compressed/for-ios/Coindcx.mp4";
import bnk from "../../assets-new/videos/bts/compressed/for-ios/Bnk.mp4";
import mentco from "../../assets-new/videos/bts/compressed/for-ios/Mentco.mp4";
import zeroB from "../../assets-new/videos/bts/compressed/for-ios/Zerob.mp4";
import greenScreen from "../../assets-new/videos/bts/compressed/for-ios/Greenscreen.mp4";
import HapagLloyd from "../../assets-new/videos/bts/compressed/for-ios/Hapag-Lloyd-Optimized.mp4";
import { sanitize } from "dompurify";
import { isMacOs, isIOS } from "react-device-detect";
import LazyLoad from "react-lazyload";

// const BtsVideoContainer = lazy(() => import("./BtsVideoContainer"));

const BTSVideos = ({ data, isBts }) => {
  // const dcxRef = useRef(null);

  // const [isPlaying, setIsPlaying] = useState(false);

  // const playVideo = () => {
  //   console.log(dcxRef);
  //   setIsPlaying(!isPlaying);

  //   if (dcxRef.current.autoplay) {
  //     dcxRef.current.currentTime = 0;
  //     dcxRef.current.play();
  //     dcxRef.current.autoplay = false;
  //     dcxRef.current.loop = false;
  //   } else {
  //     if (isPlaying) {
  //       dcxRef.current.pause();
  //     } else {
  //       dcxRef.current.play();
  //     }
  //   }
  // };

  // const vid1Ref = useRef();

  // const videoSectionRef = useRef(null);

  // const [isVisible, setIsVisible] = useState(false);

  // const variants = {
  //   hidden: { opacity: 0 },
  //   visible: { opacity: 1 },
  // };

  // useInView(videoSectionRef, { once: false }, (entry) =>
  //   setIsVisible(entry.isIntersecting)
  // );

  const mobileMediaQuery = window.matchMedia("(max-width: 500px)");

  const videoConfig = {
    ALLOWED_TAGS: ["video", "source"],
    ALLOWED_ATTRS: {
      video: ["autoplay", "loop", "muted", "poster"],
      source: ["src", "type"],
    },
  };

  return (
    <div
      className="commonSection bts-videos-section"
    // style={{
    //   display: show ? "block" : "none",
    // }}
    >
      <div className="container">
        {
          isBts
          &&
          <>
            <h2
              className="sec_title text-center margin-under-heading"
              style={{
                marginBottom: 20,
              }}
            >
              BTS
            </h2>
            <p className="psub_heading text-center">
              Raw, unedited footage revealing our secrets of film creations
            </p>
          </>
        }
        <div
          className="bts-videos-container"
        // ref={videoSectionRef}
        // variants={variants}
        // animate="visible"
        // exit="hidden"
        // initial="hidden"
        // whileInView={{
        //   once: false,
        // }}
        >
          {
            data.map((items, ind) => (
              <React.Fragment key={ind}>
                <div className="bts-video-content">
                  {/* <BtsVideoContainer videoSrc={coindcx} /> */}
                  <LazyLoad height={200} offset={1000}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: sanitize(`
                  <video
                  width="100%"
                  autoPlay
                  loop
                  muted
                  playsinline
                  preload="metadata"
                  >
                  <source src=${isMacOs || isIOS
                            ?
                            items.videoUrl
                            :
                            items.videoPath
                          } type="video/mp4" />
                  </video>
                  `),
                        videoConfig,
                      }}
                    ></div>
                  </LazyLoad>
                  {
                    items.vName
                    &&
                    <div className="hover-video-content">
                      <h5>{items.vName}</h5>
                    </div>
                  }
                </div>
              </React.Fragment>
            ))
          }
          {/* <div className="bts-video-content">
            <BtsVideoContainer videoSrc={industrialShootKelvion} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BTSVideos;
